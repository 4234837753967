import { createElement, forwardRef } from 'react';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type ButtonTypeMap = {
  props: {
    size?: 'default' | 'small' | 'compact';
    type?: 'solid' | 'outline' | 'invisible';
    color?: 'default' | 'primary' | 'accent' | 'positive' | 'alert' | 'negative';
    display?: 'block' | 'inline';
    floating?: boolean;
    fill?: boolean;
  };
  defaultComponent: 'button';
};

export type ButtonProps = OverridableComponentProps<ButtonTypeMap>;
export type ButtonType = OverridableComponent<ButtonTypeMap>;

export const Button: ButtonType = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      component = 'button',
      size = 'default',
      type = 'solid',
      color = 'default',
      display = 'block',
      floating = false,
      fill = false,
      className = '',
      ...props
    },
    ref
  ) => {
    const cssClasses = className.split(' ').concat(['o4c', 'button', color, type]);

    if (size !== 'default') cssClasses.push(size);
    if (display !== 'block') cssClasses.push(display);
    if (fill) cssClasses.push('fill');
    if (floating) cssClasses.push('floating');

    return createElement(component, { ref, className: cssClasses.join(' ').trim(), ...props });
  }
);
