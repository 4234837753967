import { ReactNode, forwardRef, createElement } from 'react';
import { BackgroundColor } from 'types/BackgroundColor';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type PillTypeMap = {
  props: {
    color?: BackgroundColor;
    display?: 'inline' | 'block';
    children?: ReactNode;
  };
  defaultComponent: 'div';
};

export type PillProps = OverridableComponentProps<PillTypeMap>;
export type PillType = OverridableComponent<PillTypeMap>;

export const Pill: PillType = forwardRef<HTMLDivElement, PillProps>(
  ({ component = 'div', color = 'bg-grey-light', display = 'inline', className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['o4c', 'pill', color]);

    if (display !== 'inline') cssClasses.push(display);

    return createElement(component, { ref: ref, className: cssClasses.join(' ').trim(), ...props });
  }
);
