import React, { HTMLProps, forwardRef, ReactElement } from 'react';
import { Icon } from '../Icon';
import { ToggleOptionProps } from './ToggleOption';

export interface ToggleProps extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  children: [ReactElement<ToggleOptionProps>, ReactElement<ToggleOptionProps>];
}
/** This component requires exactly two children of type ToggleOption */
export const Toggle = forwardRef<HTMLDivElement, ToggleProps>(({ children, value, onChange, className = '', ...props }, ref) => {
  if (children === undefined || children.length !== 2)
    throw new Error('Toggle component requires exactly two children of type ToggleOption');

  const cssClasses = className.split(' ').concat(['o4c', 'toggle']);

  const leftOption = children[0]?.props;
  const rightOption = children[1]?.props;

  if (value !== leftOption.value && value !== rightOption.value)
    console.warn(`Provided Toggle value '${value}' does not exist in available options`);

  const leftOptionSelected = value === leftOption.value;
  if (!leftOptionSelected) cssClasses.push('on');

  return (
    <div
      ref={ref}
      className={cssClasses.join(' ').trim()}
      onClick={() => onChange(leftOptionSelected ? rightOption.value : leftOption.value)}
      {...props}
    >
      <div className='handle'></div>
      <div className='left-icon'>
        <Icon name={leftOption.icon} />
      </div>
      <div className='right-icon'>
        <Icon name={rightOption.icon} />
      </div>
    </div>
  );
});
