import React, { PropsWithChildren, useEffect } from 'react';
import { O4CSSVariableOverrides } from 'types/Theme';
import { cssVariablesToString, cssVariablesFromOverrides } from './ThemeUtils';

export interface GlobalThemeProps {
  overrides: O4CSSVariableOverrides;
}

const createCSSRules = (overrides: O4CSSVariableOverrides): string => {
  const cssVariables = cssVariablesFromOverrides(overrides);
  const cssRules = cssVariablesToString(cssVariables);

  return cssRules;
};

export const GlobalTheme = React.memo<PropsWithChildren<GlobalThemeProps>>(({ overrides }) => {
  const style: string = `
    :root {
      ${createCSSRules(overrides)}
    }
  `;

  useEffect(() => {
    if (document.getElementsByClassName('pcl-global-theme-styles').length > 1) {
      console.error('Multiple global themes detected, please ensure there is only one <GlobalTheme/> component being rendered.');
    }
  }, []);

  return (
    <div className='pcl-global-theme-styles' style={{display: 'none'}}>
      <style dangerouslySetInnerHTML={{ __html: style }}></style>
    </div>
  );
});
