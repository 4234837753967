import React, { FC, HTMLProps } from 'react';

export interface DropdownOptionProps extends HTMLProps<HTMLLIElement> {
  label: string;
  value: string;
}

export const DropdownOption: FC<DropdownOptionProps> = ({ label, value, className = '', ...props }) => (
  <li className={`option ${className}`} tabIndex={0} {...props}>
    {label}
  </li>
);

DropdownOption.displayName = 'DropdownOption';