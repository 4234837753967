export interface ValidationResult {
  valid: boolean;
  error: string;
}

export type ValidatableElement = HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement | HTMLButtonElement;

export type Validator = (value: string, element: ValidatableElement) => Promise<ValidationResult>;

export const runCustomValidators = async (validators: Validator[] = [], input: ValidatableElement) => {
  for (let validator of validators) {
    let result = await validator(input.value, input);

    if (!result.valid) {
      input.setCustomValidity(result.error);
      break;
    }
  }
};

export const runValidation = async (validators: Validator[], input: ValidatableElement) => {
  //Clear any previous validation messages
  input.setCustomValidity('');

  //Run custom validators after the built-in ones
  if (input.checkValidity()) {
    await runCustomValidators(validators, input);
  }
};
