import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { InputGroup } from '../InputGroup';
import { Icon } from '../Icon';
import { Validator } from '../../util/Validation';
import { isMobile } from '../../util/isMobile';
import { useClickOutside } from '../../util/useClickOutside';
import { useValidation } from '../../util/useValidation';
import { InputGroupProps } from '../InputGroup/InputGroup';
import { isOption, isValidDropdownChild } from './Dropdown.types';
import { DropdownOptionList } from './DropdownOptionList';
import { NativeDropdownSelect } from './NativeDropdownSelect';

export type DropdownProps = {
  placeholder?: string;
  forceNativeDropdown?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  validators?: Validator[];
} & Omit<InputGroupProps, 'onChange' | 'value'>;

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      name,
      className,
      forceNativeDropdown,
      value = '',
      placeholder = '',
      disabled = false,
      required = false,
      onChange = () => {},
      children,
      validators = [],
      ...props
    },
    ref
  ) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<string>(value);
    const dropdownRef = useRef<HTMLDivElement>();
    const selectRef = useRef<HTMLSelectElement>();
    const [validate, isValidating, error] = useValidation(selectRef, validators);
    const childrenArray = React.Children.toArray(children);
    const validDropdownChildren = childrenArray.filter(isValidDropdownChild);

    let showNativeDropdown = forceNativeDropdown !== undefined ? forceNativeDropdown : isMobile();

    const dropdownCssClasses = ['o4c', 'dropdown'];
    if (showNativeDropdown) dropdownCssClasses.push('native-select');
    if (expanded) dropdownCssClasses.push('expanded');
    if (className) dropdownCssClasses.push(className);
    const dropdownClassString = dropdownCssClasses.join(' ');

    const fieldCssClasses = ['o4c', 'dropdown-field'];
    if (internalValue === '') fieldCssClasses.push('placeholder');
    if (error !== '') fieldCssClasses.push('invalid');
    const fieldClassString = fieldCssClasses.join(' ');

    const options = validDropdownChildren.filter(isOption);
    const labelForCurrentValue = options.find((option) => option.props.value === internalValue)?.props.label;

    //Close dropdown when clicked outside of the element
    useClickOutside(dropdownRef, () => setExpanded(false));

    //Validate when either the value or the select ref changes
    useEffect(() => {
      validate();
    }, [internalValue, selectRef]);

    //Call onChange when the value changes
    useEffect(() => {
      if (internalValue !== undefined) onChange(internalValue);
    }, [internalValue]);

    //Update the internal value when the value prop changes
    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <InputGroup {...props} name={name} required={required} error={error}>
        <div className={dropdownClassString} ref={dropdownRef}>
          <NativeDropdownSelect
            ref={selectRef}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            value={internalValue}
            onChange={setInternalValue}
            options={options}
          />
          <div aria-hidden={true} className='o4c input-row'>
            <div ref={ref} className={fieldClassString} onClick={() => !disabled && setExpanded(true)}>
              {internalValue !== '' ? labelForCurrentValue : placeholder}
            </div>
            <Icon name='arrow-down' aria-hidden />
          </div>
          <DropdownOptionList
            children={validDropdownChildren}
            onClick={(value) => {
              setInternalValue(value);
              setExpanded(false);
            }}
          />
        </div>
      </InputGroup>
    );
  }
);
