import React, { forwardRef, HTMLProps } from 'react';
import { TextColor } from 'types/TextColor';

export interface IconProps extends Omit<HTMLProps<HTMLElement>, 'size'> {
  name: string;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  trailing?: boolean;
  leading?: boolean;
  color?: TextColor;
}

export const Icon = forwardRef<HTMLElement, IconProps>(({ name, size = 'small', trailing, leading, color, className, ...props }, ref) => {
  const cssClasses = ['icon', `icon-${name}`];

  if (size !== 'inherit') cssClasses.push(size);
  if (trailing) cssClasses.push('trailing');
  if (leading) cssClasses.push('leading');
  if (color) cssClasses.push(color);
  if (className) cssClasses.push(className);

  const classString = cssClasses.join(' ');

  return <i className={classString} ref={ref} {...props}></i>;
});
