import React, { ReactNode, FC, HTMLProps } from 'react';

export interface FormInputErrorProps extends HTMLProps<HTMLSpanElement> {
  children?: ReactNode;
}

export const FormInputError: FC<FormInputErrorProps> = ({ children, className = '', ...props }) => (
  <span role='alert' className={`input-error-message ${className}`} {...props}>
    {children}
  </span>
);
