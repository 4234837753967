import React, { ReactNode, useState, HTMLProps, forwardRef } from 'react';
import { Validator, runValidation, ValidatableElement } from '../../util/Validation';
import { FormInputCharacterLimit } from '../FormInputCharacterLimit/FormInputCharacterLimit';
import { InputGroup } from '../InputGroup';

export type TextFieldProps = {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  resizable?: boolean;
  multiline?: boolean;
  placeholder?: string;
  icon?: ReactNode;
  validators?: Validator[];
  maxLength?: number;
  characterLimitFn?: (max: number, current: number, remaining: number) => string;
  type?: 'text' | 'email' | 'number' | 'tel' | 'url';
  name: string;
} & HTMLProps<HTMLInputElement> &
  HTMLProps<HTMLTextAreaElement>;

export const TextField = forwardRef<any, TextFieldProps>(
  (
    {
      required = false,
      disabled = false,
      multiline = false,
      resizable = false,
      placeholder = '',
      validators = [],
      maxLength,
      characterLimitFn,
      type = 'text',
      label,
      icon,
      name,
      className,
      ...props
    },
    ref
  ) => {
    const [isValidating, setIsValidating] = useState(false);
    const [error, setError] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    const validate = async (input: ValidatableElement) => {
      setIsValidating(true);
      await runValidation(validators, input);
      setError(input.checkValidity() ? '' : input.validationMessage);
      setIsValidating(false);
    };

    const handleChange = (e: React.FormEvent<any>) => {
      setCharacterCount(e.currentTarget.value?.length || 0);
      validate(e.currentTarget);
    };

    const cssClasses = ['o4c', 'text-field'];
    if (error !== '') cssClasses.push('invalid');
    if (resizable) cssClasses.push('resizable');
    if (className) cssClasses.push(className);
    const classString = cssClasses.join(' ');

    let optionalText = undefined;
    if (maxLength !== undefined) {
      optionalText = <FormInputCharacterLimit maxCount={maxLength} currentCount={characterCount} characterLimitFn={characterLimitFn} />;
    }

    const Input = multiline ? 'textarea' : 'input';

    return (
      <InputGroup {...{ name, label, required, error, optionalText }}>
        <div className='o4c input-row'>
          <Input
            id={name}
            name={name}
            className={classString}
            type={type}
            required={required}
            disabled={disabled}
            ref={ref}
            aria-label={label || name}
            aria-describedby={error !== '' ? `${name}-error-message` : ''}
            placeholder={placeholder}
            maxLength={maxLength}
            onBlur={handleChange}
            onInput={handleChange}
            {...props}
          />
          {icon !== undefined && icon}
        </div>
      </InputGroup>
    );
  }
);
