import React, { FC, HTMLProps, ReactNode } from 'react';

export interface TextFieldLabelProps extends HTMLProps<HTMLLabelElement> {
  label: string;
  required: boolean;
  name: string;
  optionalText?: ReactNode;
}

export const TextFieldLabel: FC<TextFieldLabelProps> = ({ required, label, name, optionalText = '', className = '', ...props }) => (
  <div className='input-label-row'>
    {label !== undefined && (
      <label id={`${name}-label`} htmlFor={name} className={`input-label ${className}`} {...props}>
        {label}
      </label>
    )}
    {(!required && optionalText === '') && <span className='input-label-optional'>Optional</span>}
    {(optionalText !== '') && <span className='input-label-optional'>{optionalText}</span>}
  </div>
);
