import React, { forwardRef, HTMLProps, PropsWithChildren, ReactNode, Ref } from 'react';

export interface ToggleGroupOptionProps extends Omit<PropsWithChildren<HTMLProps<HTMLInputElement>>, 'label'> {
  name?: string;
  id?: string;
  value: string;
  label?: ReactNode;
  ref?: Ref<HTMLInputElement>
}

export const ToggleGroupOption = forwardRef<HTMLInputElement, ToggleGroupOptionProps>(({ id, label, children, ...props }, ref) => {
  return (
    <label htmlFor={id}>
      <input ref={ref} id={id} type='radio' {...props} />
      <span>{label || children}</span>
    </label>
  );
});
