import React, { HTMLProps, forwardRef } from 'react';
import { Icon } from '../Icon';

export interface SwitchProps extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'onChange'> {
  value: boolean;
  onChange: (value: boolean) => void;
  showOnColor?: boolean;
  showOffColor?: boolean;
}

export const Switch = forwardRef<HTMLDivElement, SwitchProps>(
  ({ showOnColor = false, showOffColor = false, value, onChange, className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['o4c', 'switch']);

    if (value) cssClasses.push('on');
    if (showOnColor) cssClasses.push('on-color');
    if (showOffColor) cssClasses.push('off-color');

    return (
      <div ref={ref} className={cssClasses.join(' ').trim()} onClick={() => onChange(!value)} {...props}>
        <div className='handle'></div>
        <div className='on-icon'>
          <Icon name='check' />
        </div>
        <div className='off-icon'>
          <Icon name='close-bold' />
        </div>
      </div>
    );
  }
);
