import { createElement, forwardRef, ReactNode } from 'react';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type TimelineItemTypeMap = {
  props: {
    indicator?: boolean;
    color?: 'default' | 'primary' | 'accent' | 'positive' | 'alert' | 'negative';
    children?: ReactNode;
  };
  defaultComponent: 'div';
};

export type TimelineItemProps = OverridableComponentProps<TimelineItemTypeMap>;
export type TimelineItemType = OverridableComponent<TimelineItemTypeMap>;

export const TimelineItem: TimelineItemType = forwardRef<HTMLDivElement, TimelineItemProps>(
  ({ component = 'div', indicator = true, color = 'default', className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['item', `${color}`]);

    if (indicator === false) cssClasses.push('no-indicator');

    return createElement(component, { ref: ref, className: cssClasses.join(' ').trim(), ...props });
  }
);
