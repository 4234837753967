import { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';

export const RenderInBody = (props: PropsWithChildren<{}>) => {
  const wrapper = document.createElement('div');

  useEffect(() => {
    document.body.appendChild(wrapper);

    return () => {
      document.body.removeChild(wrapper);
    };
  });

  return ReactDOM.createPortal(props.children, wrapper);
};
