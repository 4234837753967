import React, { FC } from 'react';

export interface FormInputCharacterLimitProps {
  currentCount: number;
  maxCount: number;
  characterLimitFn?: (max: number, current: number, remaining: number) => string;
}

export const FormInputCharacterLimit: FC<FormInputCharacterLimitProps> = ({ currentCount, maxCount, characterLimitFn }) => {
  let output = '';
  let clampedMaxCount = Math.floor(Math.max(maxCount, 0));
  let clampedCurrentCount = Math.floor(Math.min(Math.max(currentCount, 0), clampedMaxCount));
  let remaining = Math.max(clampedMaxCount - clampedCurrentCount, 0);

  
  if (characterLimitFn !== undefined) {
    output = characterLimitFn(clampedMaxCount, clampedCurrentCount, remaining);
  } else {
    output = `${clampedCurrentCount}/${clampedMaxCount}`;
  }

  return <>{output}</>;
};
