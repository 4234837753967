import { O4CSSVariableOverrides } from 'types/Theme';

const kebabize = (str: String) => {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const cssVariablesFromOverrides = (overrides: O4CSSVariableOverrides): object => {
  let temp = {};

  for (let key of Object.keys(overrides)) {
    temp[`--${kebabize(key)}`] = overrides[key];
  }

  return temp;
};

export const cssVariablesToString = (variables: object): string => {
  let temp = '';

  for (let key of Object.keys(variables)) {
    temp += `${key}: ${variables[key]};\n`;
  }

  return temp;
};
