import React, { FC, forwardRef, HTMLProps, ReactNode } from 'react';
import { Icon } from '../Icon';

export interface ListSelectorProps extends HTMLProps<HTMLInputElement> {
  id: string;
  name: string;
  value: string;
  icon?: boolean;
  label?: string;
  inline?: boolean;
  disabled?: boolean;
  type?: 'radio' | 'checkbox';
  children?: ReactNode;
}

export const ListSelector = forwardRef<HTMLInputElement, ListSelectorProps>(
  ({ id, name, value, icon = true, label, inline = false, disabled = false, type = 'radio', children, className, ...props }, ref) => {
    const cssClasses = ['o4c', 'list-selector'];

    if (inline) cssClasses.push('inline');
    if (className) cssClasses.push(className);

    const classString = cssClasses.join(' ');

    return (
      <div className={classString}>
        <input ref={ref} id={id} disabled={disabled} name={name} type={type} aria-labelledby={`${id}-label`} {...props} />
        <div className='list-selector-body'>
          {icon === true && <Icon aria-hidden={true} name={disabled ? 'disable' : 'check'} />}
          <div id={`${id}-label`} className='list-selector-content'>
            {children !== undefined ? children : label}
          </div>
        </div>
      </div>
    );
  }
);
