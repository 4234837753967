import React, { HTMLProps } from 'react';
import { forwardRef, ReactElement } from 'react';
import { DropdownOptionProps } from './DropdownOption';

export type NativeDropdownSelectProps = {
  value: string;
  onChange: (value: string) => void;
  options: ReactElement<DropdownOptionProps>[];
  placeholder: string;
} & Omit<HTMLProps<HTMLSelectElement>, 'onChange'>;

export const NativeDropdownSelect = forwardRef<HTMLSelectElement, NativeDropdownSelectProps>(
  ({ value, onChange, options, name, placeholder, disabled, required, ...props }, ref) => (
    <select
      ref={ref}
      name={name}
      aria-label={placeholder}
      value={value}
      disabled={disabled}
      required={required}
      onChange={(e) => onChange(e.currentTarget.value)}
      {...props}
    >
      {options.map((option) => (
        <option key={option.props.value} value={option.props.value}>
          {option.props.label}
        </option>
      ))}
    </select>
  )
);
