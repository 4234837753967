import { forwardRef, createElement } from 'react';
import { BackgroundColor } from 'types/BackgroundColor';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type BadgeTypeMap = {
  props: {
    count: number;
    color?: BackgroundColor;
  };
  defaultComponent: 'div';
};

export type BadgeProps = OverridableComponentProps<BadgeTypeMap>;
export type BadgeType = OverridableComponent<BadgeTypeMap>;

export const Badge: BadgeType = forwardRef<HTMLDivElement, BadgeProps>(
  ({ component = 'div', count = 0, children, color = 'bg-grey-light', className = '', ...props }, ref) => {
    //To make sure count fits within the badge, cut it off at two digits.
    const clampedCount = Math.min(Math.max(count, 0), 99);

    //Do not render a badge when there are 0 notifications
    if (clampedCount === 0) return null;

    const cssClasses = className.split(' ').concat(['o4c', 'badge', color]);

    return createElement(component, { ref: ref, children: clampedCount, className: cssClasses.join(' ').trim(), ...props });
  }
);
