import { createElement, forwardRef } from 'react';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';

export type CardTypeMap = {
  props: {
    muted?: boolean;
  };
  defaultComponent: 'div';
};

export type CardProps = OverridableComponentProps<CardTypeMap>;
export type CardType = OverridableComponent<CardTypeMap>;

export const Card: CardType = forwardRef<any, CardProps>(({ component = 'div', muted = false, className = '', ...props }, ref) => {
  const cssClasses = className.split(' ').concat(['o4c', 'card']);

  if (muted) cssClasses.push('muted');

  return createElement(component, { ref: ref, className: cssClasses.join(' ').trim(), ...props });
});
