import React, { useState, HTMLProps, KeyboardEvent, forwardRef } from 'react';
import { Tab } from './Tab';

export interface TabsProps extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'onClick'> {
  defaultTabIndex?: number;
  onChange?: (e: MouseEvent, index: number) => void;
  onClick?: (e: MouseEvent, index: number) => void;
  children?: React.ReactElement[];
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(({ defaultTabIndex, onChange, onClick, children = [], className = '', ...props }, ref) => {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);

  const handleClick = (e: MouseEvent, index: number) => {
    if (index !== activeTabIndex) {
      if (onChange) onChange(e, index);
      setActiveTabIndex(index);
    }

    if (onClick) onClick(e, index);
  };

  const handleKeyDown = (e: KeyboardEvent, index: number) => {
    const key = e.key;

    if (key === ' ' || key === 'Enter') {
      setActiveTabIndex(index);
      e.preventDefault();
    }
  };

  return (
    <div ref={ref} role='tablist' className={`o4c tabs ${className}`} {...props}>
      {children.map((child, index) => (
        <Tab
          active={index === activeTabIndex}
          key={index}
          tabIndex={0}
          {...child.props}
          onClick={(e: MouseEvent) => {
            handleClick(e, index);
            if (child.props.onClick !== undefined) child.props.onClick(e);
          }}
          onKeyDown={(e: KeyboardEvent) => {
            handleKeyDown(e, index);
            if (child.props.onKeyDown !== undefined) child.props.onKeyDown(e);
          }}
        />
      ))}
    </div>
  );
});
