import React, { useEffect, RefObject } from 'react';

export const useClickOutside = (wrapperRef: RefObject<HTMLElement>, onClickOutside: () => void) => {
  const handleClick = (e) => {
    if (wrapperRef && !wrapperRef.current.contains(e.target)) onClickOutside();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};
