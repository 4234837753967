import React, { PropsWithChildren } from 'react';
import { O4CSSVariableOverrides } from 'types/Theme';
import { cssVariablesFromOverrides } from './ThemeUtils';

export interface ScopedThemeProps {
  overrides: O4CSSVariableOverrides;
}

export const ScopedTheme = React.memo<PropsWithChildren<ScopedThemeProps>>(({ overrides, ...props }) => {
  const style: object = cssVariablesFromOverrides(overrides);

  return <div style={style} {...props} />;
});
