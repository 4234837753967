import { RefObject, useState } from 'react';
import { runValidation, ValidatableElement } from './Validation';

export const useValidation = (
  inputRef: RefObject<ValidatableElement>,
  validators = [],
): [() => void, boolean, string] => {
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const input: ValidatableElement = inputRef?.current;

  const validate = async () => {
    if(input === undefined) return;
    
    setIsValidating(true);
    await runValidation(validators, input);
    setError(input.checkValidity() ? '' : input.validationMessage);
    setIsValidating(false);
  };

  return [validate, isValidating, error];
};
