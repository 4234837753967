import React, { forwardRef, HTMLProps, isValidElement, ReactElement, ReactNode, Ref } from 'react';
import { ToggleGroupOption, ToggleGroupOptionProps } from './ToggleGroupOption';

export interface ToggleGroupProps extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'onChange'> {
  name: string;
  size?: 'default' | 'small' | 'compact';
  color?: 'default' | 'primary' | 'accent';
  display?: 'block' | 'inline';
  value?: string;
  onChange?: (value: string) => void;
  ref: Ref<HTMLDivElement>;
}

export const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>(
  ({ name, size = 'default', color = 'default', display = 'block', value, onChange = () => {}, children, className, ...props }, ref) => {
    const cssClasses = ['o4c', 'toggle-group', `${display}`];

    if (size !== 'default') cssClasses.push(size);
    if (color !== 'default') cssClasses.push(color);
    if (className) cssClasses.push(className);

    const classString = cssClasses.join(' ');

    let childrenArray = React.Children.toArray(children);

    const childIsValidOption = (child: ReactNode): child is ReactElement<ToggleGroupOptionProps> => {
      return isValidElement(child) && 
        child.props !== undefined && 
        child.props.value !== undefined;
    }

    const options: ReactElement<ToggleGroupOptionProps>[] = childrenArray.filter(childIsValidOption);

    return (
      <div ref={ref} className={classString} {...props}>
        {options.map((child: ReactElement<ToggleGroupOptionProps>, index) => (
          <ToggleGroupOption
            key={index}
            name={name}
            checked={value !== undefined ? value === child.props.value : undefined}
            onChange={(e) => onChange(e.currentTarget.value)}
            {...child.props}
          />
        ))}
      </div>
    );
  }
);
