import React, { HTMLProps, forwardRef } from 'react';

export interface SpinnerProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  color?: 'default' | 'primary' | 'accent' | 'positive' | 'alert' | 'negative';
  size?: 'default' | 'small' | 'medium' | 'large' | 'larger';
  type: 'dots' | 'circle';
  display?: 'default' | 'block' | 'inline';
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ color = 'default', size = 'default', type, display = 'default', children, className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['o4c', 'spinner', type]);

    if (color !== 'default') cssClasses.push(color);
    if (size !== 'default') cssClasses.push(size);
    if (display !== 'default') cssClasses.push(display);

    return (
      <div ref={ref} className={cssClasses.join(' ').trim()} {...props}>
        {type === 'dots' ? <>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </> : undefined}
      </div>
    );
  }
);
