import { createElement, forwardRef } from 'react';
import { OverridableComponentProps, OverridableComponent } from 'types/OverridableComponent';

export type CardHeaderTypeMap = {
  props: {};
  defaultComponent: 'div';
};

export type CardHeaderProps = OverridableComponentProps<CardHeaderTypeMap>;
export type CardHeaderType = OverridableComponent<CardHeaderTypeMap>;

export const CardHeader: CardHeaderType = forwardRef<any, CardHeaderProps>(({ component = 'div', className = '', ...props }, ref) =>
  createElement(component, { ref, className: `header ${className}`, ...props })
);
