import React from 'react';
import { HTMLProps, forwardRef } from 'react';
import { DropdownChild, isDivider, isOption } from './Dropdown.types';
import { DropdownDivider } from './DropdownDivider';

export type DropdownOptionListProps = {
  onClick: (value: string) => void;
  children: DropdownChild[];
} & Omit<HTMLProps<HTMLUListElement>, 'onClick'>;

export const DropdownOptionList = forwardRef<HTMLUListElement, DropdownOptionListProps>(
  ({ onClick = () => {}, children = [], ...props }, ref) => (
    <ul ref={ref} className='options-container' {...props}>
      {children.map((child, index) => {
        if (isOption(child))
          return (
            <li
              key={index}
              className='option'
              onClick={() => {
                onClick(child.props.value);
              }}
            >
              {child.props.label}
            </li>
          );
        else if (isDivider(child)) 
          return <DropdownDivider key={index} />;
      })}
    </ul>
  )
);
