import React, { ReactNode, FC, HTMLProps } from 'react';
import { FormInputError } from '../FormInputError';
import { TextFieldLabel } from '../TextField/TextFieldLabel';

export interface InputGroupProps extends HTMLProps<HTMLDivElement> {
  name: string;
  label?: string;
  required?: boolean;
  error?: string;
  optionalText?: ReactNode;
  children?: ReactNode;
}

export const InputGroup: FC<InputGroupProps> = ({ name, label, required = false, error = '', optionalText, children, className = '', ...props }) => {
  return (
    <div className={`o4c input-group ${className}`} {...props}>
      {(required === false || label !== undefined || optionalText !== undefined) && <TextFieldLabel label={label} name={name} required={required} optionalText={optionalText} />}

      {children}

      {error !== '' && <FormInputError id={`${name}-error-message`}>{error}</FormInputError>}
    </div>
  );
};
