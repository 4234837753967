import { createElement, forwardRef } from 'react';
import { OverridableComponent, OverridableComponentProps } from 'types/OverridableComponent';
import { TextColor } from 'types/TextColor';

export type TypographyTypeMap = {
  props: {
    type:
      | 'display-header'
      | 'headline-header'
      | 'title-header'
      | 'sub-header'
      | 'body-text'
      | 'caption-text'
      | 'small-text'
      | 'extra-small-text';
    color?: TextColor;
  };
  defaultComponent: 'span';
};

export type TypographyProps = OverridableComponentProps<TypographyTypeMap>;
export type TypographyType = OverridableComponent<TypographyTypeMap>;

export const Typography = forwardRef<HTMLSpanElement, TypographyProps>(
  ({ component = 'span', type = 'body-text', color, className = '', ...props }, ref) => {
    const cssClasses = className.split(' ').concat(['o4c', 'typography', color, type]);
    return createElement(component, { ref: ref, className: cssClasses.join(' ').trim(), ...props });
  }
);
